import React, { useState, useEffect, useRef } from 'react';

import './Input.scss';

const Input = ({
                 type,
                 className,
                 error,
                 touched,
                 name,
                 onChange,
                 onBlur,
                 label,
                 secondLabel,
                 placeholder,
                 wrapperType,
                 value,
                 required,
                 disabled,
                 wrapperClassName,
                 hintMessage,
                 format,
                 showToggleIcon,
                 toggleIcon,
               }) => {
  const inputRef = useRef(null);
  const [internalValue, setInternalValue] = useState(value);

  useEffect(() => {
    const preventScroll = (e) => {
      e.preventDefault();
    };

    const inputElement = inputRef.current;
    if (inputElement) {
      inputElement.addEventListener('wheel', preventScroll);
    }

    return () => {
      if (inputElement) {
        inputElement.removeEventListener('wheel', preventScroll);
      }
    };
  }, []);

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  const formatValue = (value) => {
    if (!value || !format) return value;
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  };

  const unformatValue = (value) => {
    if (!value || !format) return value;
    return value.replace(/\s+/g, '');
  };

  const handleChange = (e) => {
    const rawValue = e.target.value;
    const unformattedValue = unformatValue(rawValue);
    setInternalValue(unformattedValue);
    onChange({
      ...e,
      target: {
        ...e.target,
        name,
        value: unformattedValue,
      },
    });
  };

  const handleBlur = (e) => {
    const formattedValue = formatValue(internalValue);
    setInternalValue(formattedValue);
    if (onBlur) onBlur(e);
  };

  return (
    <div
      className={`Input__wrapper ${wrapperType ? `Input__wrapper--${wrapperType}` : ''} ${
        wrapperClassName ? wrapperClassName : ''
      } ${hintMessage && error ? 'Input__wrapper--hint-error' : ''}`}
    >
      <label className="Input__label d-flex justify-content-between align-items-center">
        <div>
          {label}
          {required && <span className="text--red">*</span>}
        </div>
        {secondLabel}
      </label>
      <div className="Input__field-container">
        <input
          ref={inputRef}
          className={`Input ${className ? className : ''} ${touched && error ? 'Input--error' : ''}`}
          type={type ? type : 'text'}
          name={name}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={placeholder}
          value={formatValue(internalValue)}
          disabled={disabled}
        />
        {showToggleIcon && (
          <span className="Input__toggle-icon" onClick={toggleIcon}>
            {showToggleIcon}
          </span>
        )}
      </div>
      {touched && error && <p className="Input__error">{error}</p>}
      {hintMessage ? (
        <p className={`Input__hint ${touched && error ? 'Input__hint--with-error' : ''}`}>
          {hintMessage}
        </p>
      ) : null}
    </div>
  );
};

export default Input;
