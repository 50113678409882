import { combineReducers } from 'redux';
import utilityReducer from './utility.reducer';
import authReducer from './auth.reducer';
import donationsReducer from './donations.reducer';

const appReducer = combineReducers({
  utility: utilityReducer,
  auth: authReducer,
  donations: donationsReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'AUTH/USER_LOGOUT') {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
