import api from './api';

export function getBeneficiariesList({ programType, page, city }) {
  return api.get(
    `/beneficiaries?${programType ? `program=${programType}` : ''}${page ? `&page=${page}` : ''}${
      city ? `&city=${city}` : ''
    }`,
  );
}

export function getPrograms() {
  return api.get('/programs/');
}

export function getDefaultDenominations() {
  return api.get('/default-amounts/');
}

export function getPaymentsHistory(page = 1) {
  return api.get(`/payments/?page=${page}`);
}

export function calculateZakyat(data) {
  return api.post(`/zakyat-calculate/`, data);
}

export function createDonation(data) {
  return api.post('/donations/', data);
}

export function createBeneficiaryAmount({ beneficiaryId, amount }) {
  return api.post('/beneficiaryAmount/', {
    beneficiary: beneficiaryId,
    certificate_amount: amount,
  });
}

export function createPayment({ donationId }) {
  return api.post('/payments/', { donation: donationId });
}

export function initPayboxPayment({ orderId }) {
  return api.post(`/payments/${orderId}/init_payment_paybox/`, {});
}
